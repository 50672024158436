<!-- 拣货产品记录 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem>
        <span class="marginLeft40">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_name" clearable filterable class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.model_name" :disabled="!queryFrom.product_name" clearable filterable multiple :max-tag-count="queryFrom.model_name.length == 1 ? 1 : 0" class="iviewIptWidth250" @on-change="changeModel" label-in-value>
          <Option v-for="(item, index) in productModelList" :value="item.model_name" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 批号 -->
      <FormItem class="marginLeft40">
        <span>批号：</span>
        <Input placeholder="请输入" v-model="queryFrom.batch_number" class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 拣货人 -->
      <FormItem class="marginLeft40">
        <span>拣货人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in pickerListist" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 拣货时间 -->
      <FormItem>
        <span>拣货时间：</span>
        <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.create_time_start" :editable="false" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.create_time_end" :options="options" :editable="false" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 仓库名称 -->
      <FormItem class="marginLeft40">
        <span>仓库名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseNameList" :value="item.warehouse_name" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="getGspWarehousePickingProducts(true)">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spin style="display: inline-block;" v-show="Loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          合并导出
        </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnSure marginLeft20" @click="printing">打印</span>
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录">
      <div slot="summary" class="summary" v-if="listData && listData.length > 0">
        总数量：<span class="color389 marginRight20">{{ total_quantity }}</span>
        合计金额：<span class="color389">{{ $utils.formatMoney(tota_amount, true) }}</span>
      </div>
    </Table> -->
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" highlight-row border :chaxunFlag="chaxunFlag" :rowClass="rowClassName"></Table>
    </div>
    <div class="summary" v-if="listData && listData.length > 0">
      总数量：<span class="color389 marginRight20">{{ total_quantity }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(tota_amount, true) }}</span>
    </div>
    <div class="pages" v-if="listData !== null && listData.length > 0 && total">
      <Page v-if="queryFrom && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
      <Page v-if="queryFrom && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
    </div>
    <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
      <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
    </div> -->
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'

export default {
  name: 'recordPickingProductsConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      Loading: false,
      tableHeight: 0,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 11,
      typeList: [],
      status: false,
      titleList: [],
      option_page: '08',
      // 动态表头弹窗
      setupStatus: false,
      options: {},
      // 总条数
      total: undefined,
      // 总金额
      tota_amount: undefined,
      // 总数量
      total_quantity: undefined,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 90,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '拣货人',
          key: 'create_by',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '拣货时间',
          key: 'create_time',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '仓库名称',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 100,
        },
      ],
      // 仓库名称下拉
      warehouseNameList: [],
      // 拣货人下拉
      pickerListist: [],
      // 规格/SKU下拉
      productModelList: [],
      // 产品名称下拉
      productList: [],
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        product_code: '',
        product_name: '',
        model_name: '',
        item_number: '',
        batch_number: '',
        create_time_start: '',
        create_time_end: '',
        create_by: '',
        warehouse_name: '',
      },
      // 产品ID
      product_id: '',
      paging: null,
    }
  },
  mounted() {
    this.queryOption()
    this.getProductList()
    this.getPermissionWarehouseList()
    this.getPickerListist()
  },
  methods: {
    printing() {
      this.status = true
      this.getTemplate()
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, false).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$apiConsign.gspWarehousePickingProducts.url}?`
      console.log(this.queryFrom)
      for (let objName in this.queryFrom) {
        console.log(objName)
        if (this.queryFrom[objName]) {
          api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
        }
      }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      console.log(api_info)
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '拣货产品记录.xlsx'
        aLink.click()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.tabloading = true
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          this.tabloading = false
          // this.getGspWarehousePickingProducts()
        })
    },
    // 改变规格型号/sku
    changeModel(foo) {
      // console.log(foo);
    },
    // 合并导出
    exportMerge() {
      let product_id = ''
      let specification_id_str = ''
      let warehouse_id = ''
      this.productList.forEach(item => {
        if (item.product_name == this.queryFrom.product_name) {
          product_id = item.product_id
        }
      })
      let data = {
        product_id: product_id,
        product_code: this.queryFrom.product_code,
        specification_id_str: specification_id_str,
        warehouse_name: this.queryFrom.warehouse_name,
        batch_number: this.queryFrom.batch_number,
        create_by: this.queryFrom.create_by,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
      }
      this.Loading = true
      this.$http.downFile(this.$apiConsign.gspWarehousePickingLineExport, data).then(res => {
        this.Loading = false
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '拣货产品记录导出表.xlsx'
        aLink.click()
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsPurchaseOrderRecordConsign',
      })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.getGspWarehousePickingProducts()
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.create_time_start = e
        // 清空截止日期
        if (e > this.queryFrom.create_time_end) {
          this.queryFrom.create_time_end = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start) - 86400000
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    // 拣货人下拉
    getPickerListist() {
      this.$http.get(this.$apiConsign.pickerListist).then(res => {
        this.pickerListist = res.data
      })
    },
    // 拣货仓库 下拉
    getPermissionWarehouseList() {
      this.$http.get(this.$apiConsign.permissionWarehouseList, { page: 1, rows: 1000 ,is_consign:1}, false).then(res => {
        this.warehouseNameList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.productModelList = []
      this.queryFrom.model_name = ''
      if (!e) return
      // 规格/SKU下拉
      let product_id
      this.productList.forEach(item => {
        if (item.product_name == e) {
          product_id = item.product_id
        }
      })
      let data = {
        search_type: '1',
        product_id: product_id,
      }
      this.$http.get(this.$apiConsign.productShow, data).then(res => {
        this.productModelList = res.data
      })
    },
    // 产品名称 下拉
    getProductList() {
      this.$http.get(this.$apiConsign.productShow, { search_type: '1' }).then(res => {
        this.productList = res.data
      })
    },
    // 获取数据
    getGspWarehousePickingProducts(query = false) {
      this.chaxunFlag = false
      this.tabloading = true
      if (query) {
        this.queryFrom.page = 1
      }
      this.$http.get(this.$apiConsign.gspWarehousePickingProducts, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data.result
        this.total = res.data.total_count
        this.tota_amount = res.data.tota_amount
        this.total_quantity = res.data.total_quantity
        // 处理动态表头页面列表数据
        this.listData = this.$utils.dynamicHeaderList(this, this.listData)
        if (this.listData.length > 0) {
          this.$nextTick(() => {
            this.tableHeight = this.$refs.tabDiv.offsetHeight + ''
          })
        } else {
          this.$nextTick(() => {
            this.tableHeight = '0'
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.loadingStyle {
  display: inline-block;
}
.home {
  display: flex;
  flex-direction: column;
}
// 表格
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
</style>
